<template>
  <div
    class="input-check"
    @click="toggle"
    @keydown.enter="toggle"
    ref="checkElem"
    v-class-mod:input-check="[`button-size-${buttonSize}`, `theme-${theme}`, { isDisabled }]"
  >
    <icon-toggle-button
      :icon="icon"
      :is-disabled="isDisabled"
      :is-loading="isLoading || isInternalLoading"
      :action="toggle"
      :icon-color="color"
      :icon-hover-color="color"
      :icon-size="iconSize"
      :button-size="buttonSize"
      :hover-on-parent="!isDisabled"
      @click.stop
    />
    <span
      v-if="label"
      class="input-check__label"
      v-class-mod:input-check__label="{ labelStrikethrough }"
      v-set-colors="{ color: isDisabled ? disabledColor || 'black-50' : undefined }"
    >
      <cut-text v-if="!disableCutText" :text="label" />
      <template v-else>{{ label }}</template>
    </span>
    <icon-indicator v-if="isNumber(number)" :indicator="{ amount: number }" class="input-check__icon-indicator" />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";

import { vSetColors } from "@horizon56/directives";
import { IconName } from "@horizon56/fonts/types";
import type { ButtonSize, ColorName, IconSize } from "@horizon56/styles/types";
import { isNumber, shareState } from "@horizon56/utils";

import IconToggleButton from "@/components/buttons/icon-toggle-button.vue";
import CutText from "@/components/content/cut-text.vue";
import IconIndicator from "@/components/icons/icon-indicator.vue";

const checkStates = ["checked", "indeterminate", "unchecked"] as const;
export type State = (typeof checkStates)[number];

const props = withDefaults(
  defineProps<{
    state: State;
    states?: State[];
    buttonSize?: ButtonSize;
    iconSize?: IconSize;
    isDisabled?: boolean;
    isLoading?: boolean;
    label?: string;
    theme?: "light-blue" | "black-90" | "green";
    disabledColor?: ColorName;
    labelStrikethrough?: boolean;
    disableCutText?: boolean;
    checkGroupKey?: string;
    number?: number;
    update?: (s: State, isShringState: boolean) => Promise<void> | void;
  }>(),
  {
    states: () => ["unchecked", "checked"],
    buttonSize: "medium",
    iconSize: "medium",
    theme: "light-blue",
  },
);

const emit = defineEmits<{
  (e: "update", v: State): void;
  (e: "isShringState", a: boolean): void;
}>();

const checkElem = ref<Element>();
const sharing = ref<ReturnType<typeof shareState<State>> | undefined>();
const isInternalLoading = ref(false);

const isChecked = computed(() => props.state === "checked");
const isIndeterminate = computed(() => props.state === "indeterminate");

const icon = computed((): IconName => {
  if (isChecked.value) {
    return "check_box";
  } else if (isIndeterminate.value) {
    return "indeterminate_check_box";
  }
  return "check_box_outline_blank";
});

const color = computed((): ColorName => {
  if (isChecked.value) {
    if (props.theme === "light-blue") {
      return "light-blue-vivid";
    } else if (props.theme === "green") {
      return "green-vivid";
    }
    return "black-90";
  }
  if (isIndeterminate.value) {
    return "black-90";
  }
  return "black-50";
});

const toggle = async () => {
  if (props.isDisabled) {
    return;
  }
  const state = nextState.value;
  if (typeof sharing?.value?.toggle === "function") {
    sharing?.value?.toggle(state);
  } else {
    onUpdate(state, false);
  }
};

const onUpdate = async (state: State, isSharingState: boolean) => {
  if (props.isDisabled) {
    return;
  }
  if (isSharingState) {
    emit("isShringState", true);
  }
  emit("update", state);
  if (typeof props.update === "function") {
    isInternalLoading.value = true;
    await props.update(state, isSharingState);
    isInternalLoading.value = false;
  }
  if (isSharingState) {
    emit("isShringState", false);
  }
};

const nextState = computed(() => {
  const index = props.states.indexOf(props.state);
  if (index < props.states.length - 1) {
    return props.states[index + 1];
  }
  return props.states[0];
});

onMounted(() => {
  if (props.checkGroupKey) {
    sharing.value = shareState<State>({
      node: () => checkElem.value,
      key: props.checkGroupKey,
      onUpdate,
    });
  }
});

onBeforeUnmount(() => sharing.value?.remove());
</script>

<style lang="scss" scoped>
.input-check {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  column-gap: var(--app-spacing-size-xxsmall);

  --label-height: calc(var(--app-font-size-base) * 1.4);
  &__label {
    height: var(--label-height);
    line-height: var(--label-height);
    margin-top: var(--label-offset);
    &--labelStrikethrough {
      text-decoration: line-through;
    }
  }
  &__icon-indicator {
    margin-left: auto;
    margin-top: var(--indicator-offset);
  }
  @each $size in $buttonSizes {
    &--button-size-#{$size} {
      --label-offset: calc((var(--app-button-height-#{$size}) - var(--label-height)) / 2);
      --indicator-offset: calc((var(--app-button-height-#{$size}) - var(--app-icon-size-large)) / 2);
    }
  }
  &--isDisabled &__label {
    pointer-events: none;
  }
  &:not(&--isDisabled) {
    cursor: pointer;
  }
}
</style>
